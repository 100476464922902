import { z } from 'zod'

export const LoginRequestFactory = z.object({
  email: z.string().default(''),
  password: z.string().default(''),
  remember: z.boolean().default(false)
})

export type LoginRequestFactory = z.infer<typeof LoginRequestFactory>

export const LoginRequest = z.object({
  email: z.string({ required_error: 'auth.validation.email' }).email({ message: 'auth.validation.email_format' }),
  password: z.string({ required_error: 'auth.validation.password' }),
  remember: z.boolean({ required_error: 'auth.validation.remember' })
})

export type LoginRequest = z.infer<typeof LoginRequest>
