import { createApp } from 'vue'
import { initSentry, sentryEnabled } from '@/plugins/sentry.ts'

import App from './App.vue'
import router from './router'
import './assets/main.css'

const app = createApp(App)

if (import.meta.env.VITE_ENABLE_MOCK === 'true' && import.meta.env.DEV) {
  const { worker } = await import("@/mocks/handlers");
  worker.start();
}

if (sentryEnabled) {
  initSentry(app, router)
}

app.config.performance = true

app.use(router)
app.mount('#app')
