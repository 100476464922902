import { z } from 'zod'

export const AuthUserPermissions = z.enum(['viewProjects', 'viewLibrary', 'createProject'])

export type AuthUserPermissions = z.infer<typeof AuthUserPermissions>;

export const AuthUserResource = z.object({
  data: z.object({
    id: z.string().uuid(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    stats: z.object({
      new_documents: z.number(),
      unread_notifications: z.number(),
      open_tasks: z.number(),
    }).default({
      new_documents: 0,
      unread_notifications: 0,
      open_tasks: 0
    })
    // permissions: z.array(AuthUserPermissions)
  })
})
export type AuthUserResource = z.infer<typeof AuthUserResource>
