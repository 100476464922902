<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useShortcut } from './helpers/shortcuts'
import AlertModalWrapper from '@/components/modals/AlertModalWrapper.vue'
import ShortcutModal from '@/components/modals/ShortcutModal.vue'
import { RouteLocationNormalized, RouterView, useRoute, useRouter } from 'vue-router'


const isShortcutModalOpen = ref(false)
useShortcut('slash', () => {
  isShortcutModalOpen.value = true
})

const route = useRoute()
const router = useRouter()

const handleQueryStates = (route: RouteLocationNormalized) => {
  // Open shortcut modal
  if ('shortcuts' in route.query) isShortcutModalOpen.value = true
}

onMounted(async () => handleQueryStates(route))
watch(route, handleQueryStates)

function removeShortcutQuery() {
  const query = { ...route.query }
  delete query.shortcuts
  router.replace({ ...route, query })
}
</script>

<template>
  <RouterView />
  <AlertModalWrapper />
  <ShortcutModal v-model:is-open="isShortcutModalOpen" @update:is-open="removeShortcutQuery"/>
</template>
