import { authPlugin } from '@/plugins/auth'
import { useAuthState } from '@/states/useAuthState'
import { createRouter, createWebHistory } from 'vue-router'

const { user } = useAuthState()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/DashboardIndex.vue'),
      meta: authPlugin.authRouteMeta
    },
    {
      path: '/library',
      name: 'library',
      component: () => import('@/views/library/DocumentsOverview.vue'),
      meta: authPlugin.authRouteMeta,
      beforeEnter: [() => (user.value?.can('viewLibrary') ? true : { name: 'dashboard' })]
    },
    {
      path: '/published-documents',
      name: 'published-documents',
      component: () => import('@/views/published/PublishedOverview.vue'),
      meta: authPlugin.authRouteMeta,
      beforeEnter: [() => (user.value?.can('viewLibrary') ? true : { name: 'dashboard' })]
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/SettingsLayout.vue'),
      meta: authPlugin.authRouteMeta,
      redirect: () => ({ name: 'account-settings' }),
      children: [
        {
          path: 'account',
          name: 'account-settings',
          component: () => import('@/views/settings/AccountSettings.vue'),
          meta: authPlugin.authRouteMeta,
          beforeEnter: [() => (user.value?.can('viewProjects') ? true : { name: 'dashboard' })]
        },
        {
          path: 'notifications',
          name: 'notifications-settings',
          component: () => import('@/views/settings/NotificationsSettings.vue'),
          meta: authPlugin.authRouteMeta,
          beforeEnter: [() => (user.value?.can('viewProjects') ? true : { name: 'dashboard' })]
        }
      ]
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/projects/ProjectsOverview.vue'),
      meta: authPlugin.authRouteMeta,
      beforeEnter: [() => (user.value?.can('viewProjects') ? true : { name: 'dashboard' })]
    },
    {
      path: '/projects/:project_id',
      component: () => import('@/views/project/ProjectLayout.vue'),
      meta: authPlugin.authRouteMeta,
      children: [
        {
          path: '',
          name: 'single-project',
          component: () => import('@/views/project/ProjectDashboard.vue'),
          meta: authPlugin.authRouteMeta
        },
        {
          path: 'publications',
          name: 'single-project-publications',
          component: () => import('@/views/project/ProjectPublications.vue'),
          meta: authPlugin.authRouteMeta
        },
        {
          path: 'data-connections',
          name: 'single-project-data-connections',
          component: () => import('@/views/project/workflow/WorkflowOverview.vue'),
          meta: authPlugin.authRouteMeta
        },
        {
          path: 'data-connections/:workflow_id',
          component: () => import('@/views/project/workflow/WorkflowLayout.vue'),
          meta: authPlugin.authRouteMeta,
          children: [
            {
              path: '',
              name: 'single-project-data-connection',
              components: {
                default: () => import('@/views/project/workflow/schema/SchemaOverview.vue')
              },
              meta: authPlugin.authRouteMeta
            },
            {
              path: 'schema/:schema_id',
              name: 'single-project-data-connection-schema',
              components: {
                default: () => import('@/views/project/workflow/schema/SchemaOverview.vue'),
                schema: () => import('@/views/project/workflow/schema/SchemaDetail.vue')
              },
              meta: authPlugin.authRouteMeta
            }
          ]
        },
        {
          path: 'team',
        name: 'single-project-team',
          component: () => import('@/views/project/ProjectTeam.vue'),
          meta: authPlugin.authRouteMeta
        },
        {
          path: 'documents',
          name: 'single-project-document-builders',
          component: () => import('@/views/project/DocumentBuilderOverview.vue'),
          meta: authPlugin.authRouteMeta
        },
        {
          path: 'documents/:document_builder_id',
          name: 'single-project-document-builder',
          component: () => import('@/views/project/document_builder/DocumentBuilderLayout.vue'),
          meta: authPlugin.authRouteMeta,
          children: [
            {
              path: 'components/:component_id',
              name: 'single-project-document-builder-component',
              component: () => import('@/views/project/document_builder/Component.vue'),
              meta: authPlugin.authRouteMeta
            },
          ]
        },
        {
          path: 'settings',
          name: 'single-project-settings',
          component: () => import('@/views/project/ProjectTeam.vue'),
          meta: authPlugin.authRouteMeta
        }
      ]
    },
    {
      path: '/',
      name: 'auth',
      component: () => import('@/views/auth/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'auth-login',
          component: () => import('@/views/auth/AuthLogin.vue')
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () => import('@/views/auth/ForgotPassword.vue')
        },
        {
          path: '/reset-password',
          name: 'reset-password',
          component: () => import('@/views/auth/ResetPassword.vue'),
          beforeEnter: [({ query }) => (query.token && query.email ? true : { name: 'auth-login' })]
        }
      ]
    }
  ]
})

if (import.meta.env.DEV) {
  router.addRoute({
    path: '/ui',
    name: 'ui',
    component: () => import('@/views/UI.vue'),
  })
}

router.beforeEach(async (to) => authPlugin.useAuthenticationNavigationGuard(to))

export default router
