<script lang="ts">
export const ButtonType = z.enum(['primary', 'secondary', 'destructive']);
export type ButtonType = z.infer<typeof ButtonType>;
</script>

<script setup lang="ts">
import IconSpin from '@/components/icons/IconSpin.vue'
import { ButtonHTMLAttributes, computed, ref, watch } from 'vue'
import { z } from 'zod'

const props = withDefaults(
  defineProps<{
    buttonType?: ButtonHTMLAttributes['type']
    type?: ButtonType
    disabled?: boolean
    isLoading?: boolean
    loadingText?: string
    minimumLoadingTime?: number
  }>(),
  {
    buttonType: 'button',
    type: 'primary',
    isLoading: false,
    loadingText: '',
    minimumLoadingTime: 1000
  }
)
let startLoading: number | undefined
const showLoadingState = ref<boolean>(false)
watch(
  props,
  ({ isLoading }) => {
    if (isLoading) {
      showLoadingState.value = true
      startLoading = performance.now()
    } else {
      if (!startLoading) return
      const remainingTime = props.minimumLoadingTime - (performance.now() - startLoading)
      if (remainingTime <= 0) showLoadingState.value = false
      else setTimeout(() => (showLoadingState.value = false), remainingTime)
    }
  },
  { immediate: true }
)

const typeClasses = computed(() => {
  switch (props.type) {
    case 'primary':
      return 'bg-primary-600 text-white hover:bg-primary-500 focus-visible:outline-primary-600'
    case 'secondary':
      return 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-gray-300'
    case 'destructive':
      return 'bg-red-600 text-white shadow-sm hover:bg-red-500'
  }
})
</script>


<template>
  <button
    :type="props.buttonType"
    class="relative inline-flex items-center justify-center gap-x-3 rounded-md px-5 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
    :class="[typeClasses]"
    :disabled="disabled ? true : undefined"
  >
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <span
        v-if="showLoadingState"
        aria-hidden="true"
        class="absolute inset-0 flex items-center justify-center gap-x-3 whitespace-nowrap"
      >
        <IconSpin class="shrink-0 h-5 w-5 animate-spin"></IconSpin>
        {{ loadingText }}
      </span>
      <span
        class="absolute inset-0 flex items-center justify-center gap-x-2 whitespace-nowrap"
        aria-hidden="true"
        v-else
      >
        <slot></slot>
      </span>
    </Transition>
    <span class="flex items-center justify-center gap-x-2 whitespace-nowrap opacity-0">
      <slot></slot>
    </span>
  </button>
</template>