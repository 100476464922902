<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import KeyboardShortcut from '../misc/KeyboardShortcut.vue'

const isOpen = defineModel<boolean>('isOpen', { required: true })

const shortcutSections = [
  {
    title: 'Library',
    shortcuts: [
      {
        key: 'A',
        title: 'Add to project'
      },
      {
        key: 'F',
        title: 'Add to favorites'
      },
      {
        key: '[1...10]',
        title: 'Select document'
      }
    ]
  },
  {
    title: 'Project overview',
    shortcuts: [
      {
        key: 'N',
        title: 'Create new project'
      },
      {
        key: 'U',
        title: 'Update project details'
      },
      {
        key: '&#9003;',
        title: 'Delete project'
      },
      {
        key: '[1...10]',
        title: 'Select project'
      }
    ]
  }
]
</script>

<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-50" @close="isOpen = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/50 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6"
            >
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900"
                    >Available shortcuts</DialogTitle
                  >
                  <div class="my-8 grid grid-cols-2 gap-4 text-start">
                    <div v-for="(section, index) in shortcutSections" :key="index">
                      <h2 class="mb-2">{{ section.title }}</h2>
                      <div class="grid grid-cols-1 gap-y-2">
                        <template v-for="shortcut in section.shortcuts" :key="shortcut.title">
                          <dl class="flex flex-nowrap gap-x-3">
                            <dt class="flex-grow truncate text-sm text-gray-600">
                              {{ shortcut.title }}
                            </dt>
                            <dd class="text-end leading-6 text-gray-500">
                              <KeyboardShortcut :shortcut="shortcut.key" />
                            </dd>
                          </dl>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  @click="isOpen = false"
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
