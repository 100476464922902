import { definePlugin, defaultConfig } from '@eli5/vue-auth'
import { AuthUserResource } from '@/resources/users/AuthUserResource'
import { LoginRequestFactory, LoginRequest } from '@/requests/auth/LoginRequest'
import { ResetPasswordRequestFactory, ResetPasswordRequest } from '@/requests/auth/ResetPasswordRequest'

const customConfig = {
  ...defaultConfig,
  base_url: import.meta.env.VITE_API_URL,
  AuthUserResource,
  LoginRequestFactory,
  LoginRequest,
  ResetPasswordRequestFactory,
  ResetPasswordRequest,
}

export const authPlugin = definePlugin(customConfig)
