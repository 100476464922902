export function getInitials(fullName: string) {
  const [firstName, ...restNames] = fullName.toUpperCase().trim().split(' ')


  if (!restNames.length && firstName) {
    return firstName.substring(0,2)
  } else if (restNames && firstName) {
    const firstNameInitial = firstName[0]
    const lastNameInitial = restNames.pop()?.[0]

    return `${firstNameInitial}${lastNameInitial}`
  } else {
    return ''
  }
}
