import { computed } from 'vue'
import { authPlugin } from '@/plugins/auth'
import { AuthUserModel } from '@/models/users/AuthUserModel'


const store = authPlugin.useAuthStore()
const service = authPlugin.useAuthService()

const user = computed(() => store.user.value ? AuthUserModel(store.user.value) : undefined)

export function useAuthState() {
  return {
    user,
    isAuthenticated: store.isAuthenticated,
    logout: service.logout,
    setUser: store.set,
    service,
  }
}
