import { z } from 'zod'
import { ref } from 'vue'
import { sleep } from '@eli5/js-helpers'
import { ButtonType } from '@/components/buttons/DefaultButton.vue'

export const AlertMessage = z.object({
  title: z.string(),
  message: z.string(),
  buttonLabel: z.string().default('OK'),
  buttonType: ButtonType.default('primary'),
  state: z.enum(['success', 'warning', 'error']).default('warning'),
  onCancel: z.function().returns(z.void()).optional(),
  action: z.object({
    label: z.string(),
    action: z.function().returns(z.void()),
    buttonType: ButtonType.default('primary'),
  }).optional()
})

export type AlertMessage = z.input<typeof AlertMessage>

const isAlertShown = ref(false)
const alertMessage = ref<AlertMessage>()

function showAlert(message: AlertMessage) {
  alertMessage.value = AlertMessage.parse(message)
  isAlertShown.value = true
}

async function hideAlert() {
  isAlertShown.value = false
  if (alertMessage.value?.onCancel) alertMessage.value.onCancel()
  // Note: This is tied to the duration of the animation the modal is shown/hidden in AlertModal.vue
  await sleep(300)
  alertMessage.value = undefined
}

function showAdditionalPermissionsRequiredAlert() {
  showAlert({
    title: 'Additional permissions required',
    message:
      'You do not have enough permissions to create a project, Please contact your administrator about this issue.'
  })
}


export function useAlertState() {
  return {
    isAlertShown,
    alertMessage,
    showAlert,
    hideAlert,
    showAdditionalPermissionsRequiredAlert
  }
}