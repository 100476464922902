<script lang="ts" setup>
import { computed } from 'vue'
import { AlertMessage } from '@/states/useAlertState'
import DefaultButton from '@/components/buttons/DefaultButton.vue'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const isShown = defineModel<boolean>('modelValue', { required: true })
const props = defineProps<{ alert: AlertMessage }>()

const icon = computed(() => {
  switch (props.alert.state) {
    case 'error':
    case 'warning':
      return ExclamationTriangleIcon
    case 'success':
      return CheckIcon
  }
})
</script>

<template>
  <TransitionRoot as="template" :show="isShown">
    <Dialog as="div" class="relative z-50" @close="isShown = false">
      <!-- NOTE: if you want to change the duration, please also update the duration of the sleep function in hideAlert on useAlertState -->
      <!-- This will only remove the state when the animation has been finished -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/50 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
                  :class="{
                    'bg-green-100': props.alert.state === 'success',
                    'bg-red-100': props.alert.state === 'error',
                    'bg-yellow-100': props.alert.state === 'warning'
                  }"
                >
                  <component
                    :is="icon"
                    class="h-6 w-6"
                    aria-hidden="true"
                    :class="{
                      'text-green-600': props.alert.state === 'success',
                      'text-red-600': props.alert.state === 'error',
                      'text-yellow-600': props.alert.state === 'warning'
                    }"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{
                    props.alert.title ?? ''
                  }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500" v-html="props.alert.message ?? ''" />
                  </div>
                </div>
              </div>
              <div class="mt-5 flex gap-2 sm:mt-6">
                <DefaultButton
                  class="w-full"
                  :type="props.alert.buttonType"
                  @click="isShown = false"
                >
                  {{ props.alert.buttonLabel }}
                </DefaultButton>
                <DefaultButton
                  class="w-full"
                  v-if="props.alert.action"
                  :type="props.alert.action.buttonType"
                  @click="props.alert.action.action"
                >
                  {{ props.alert.action.label }}
                </DefaultButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
