import { getInitials } from '@/helpers/getInitials'
import { UserResource } from '@/resources/users/UserResource'

export function UserModel (user: UserResource) {
  return {
    ...user,
    get full_name(): string {
      return `${this.first_name} ${this.last_name}`
    },
    get picture(): string {
      return this.full_name
    },
    get initials (): string {
      return getInitials(this.full_name)
    }
  }
}

export type UserModel = ReturnType<typeof UserModel>