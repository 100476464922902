import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'

export const sentryEnabled = import.meta.env.PROD
const envDsn = import.meta.env.VITE_SENTRY_DSN

const initSentry = (app: App<Element>, router: Router) => {
  if (sentryEnabled && envDsn) {
    Sentry.init({
      app,
      dsn: envDsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ],
      trackComponents: true,
      hooks: ['activate', 'mount', 'update', 'unmount'],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })
  }
}

const NotifySentry = (e: unknown) => {
  if (sentryEnabled && e instanceof Error) Sentry.captureException(e)
}

export { NotifySentry, initSentry }
