import { useMagicKeys, whenever } from '@vueuse/core'

const keys = useMagicKeys()
// Keys
// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_code_values
// Icons
// https://apple.stackexchange.com/questions/55727/where-can-i-find-the-unicode-symbols-for-mac-functional-keys-command-shift-e
// TODO: 
// Please check this, i removed shift for now since the key 2 can become a @ so i need to listen to a lot more 
// control + option isnt an option because it wont listen to any other key after that so the 1 in control+alt+1 is ignored
const defaultKeys = {
  // 'shift': '&#8679;',
  'control': '&#8963;'
}

export function useShortcut(key: string, callback: () => void) {
  const shortcut = keys[[...Object.keys(defaultKeys), key].join('+')]
  if (shortcut) whenever(shortcut, callback)
}

export function useShortcuts<T = string | number>(key: Array<T>, callback: (key: T) => void) {
  for (const k of key) {
    const shortcut = keys[[...Object.keys(defaultKeys), k].join('+')]
    if (shortcut) whenever(shortcut, () => callback(k))
  }
}

export function getKeys(key: string) {
  return [...Object.values(defaultKeys), key]
}
