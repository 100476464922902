import { AuthUserPermissions, AuthUserResource } from '@/resources/users/AuthUserResource'
import { UserModel } from './UserModel'

export function AuthUserModel (user: AuthUserResource) {
  return {
    ...user.data,
    ...UserModel(user.data),
    can(key: AuthUserPermissions) {
      // TODO: remove this when backend has permissions in place
      return !!key
      // return this.permissions.includes(key)
    },
  }
}

export type AuthUserModel = ReturnType<typeof AuthUserModel>