<script lang="ts" setup>
import { useAlertState } from '@/states/useAlertState'
import AlertModal from '@/components/modals/AlertModal.vue'

const { hideAlert, isAlertShown, alertMessage } = useAlertState()
</script>

<template>
  <AlertModal v-if="alertMessage" :alert="alertMessage" v-model="isAlertShown" @update:modelValue="hideAlert" />
</template>
