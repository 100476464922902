import { z } from 'zod'

export const ResetPasswordRequestFactory = z.object({
  token: z.string().default(''),
  email: z.string().default(''),
  password: z.string().default(''),
  repeat_password: z.string().default(''),
})


export const ResetPasswordRequest = z.object({
  token: z.string({ required_error: 'auth.validation.token' }).min(1, { message: 'auth.validation.token' }),
  email: z.string().default(''),
  password: z.string({ required_error: 'auth.validation.password' }).min(1, { message: 'auth.validation.password' }),
  repeat_password: z.string({ required_error: 'auth.validation.email' }).min(1, { message: 'auth.validation.email' }),
})

export type ResetPasswordRequest = z.infer<typeof ResetPasswordRequest>
